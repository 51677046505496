<template>
  <div class="z-100 relative emoji-text-fields">
        <input v-if="input" type="text" v-model="value" class="input pr-10" @input="update" />
        <textarea-autosize
            v-else
            v-model="value"
            :min-height="30"
            :max-height="86"
            class="input pr-8 textarea"
            @input="update"
        />

        <emoji-picker @emoji="append" :search="search">
            <div
                class="emoji-invoker"
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="clickEvent"
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.47581 2 2 6.47581 2 12C2 17.5242 6.47581 22 12 22C17.5242 22 22 17.5242 22 12C22 6.47581 17.5242 2 12 2ZM12 20.0645C7.55242 20.0645 3.93548 16.4476 3.93548 12C3.93548 7.55242 7.55242 3.93548 12 3.93548C16.4476 3.93548 20.0645 7.55242 20.0645 12C20.0645 16.4476 16.4476 20.0645 12 20.0645ZM16.75 14.1613C16.3387 13.8185 15.7298 13.875 15.3871 14.2863C14.5484 15.2944 13.3105 15.875 12 15.875C10.6895 15.875 9.45161 15.2984 8.6129 14.2863C8.27016 13.875 7.65726 13.8226 7.25 14.1613C6.83871 14.504 6.78629 15.1129 7.125 15.5242C8.33468 16.9758 10.1129 17.8065 12 17.8065C13.8871 17.8065 15.6653 16.9758 16.875 15.5242C17.2177 15.1129 17.1613 14.504 16.75 14.1613ZM8.77419 11.3548C9.4879 11.3548 10.0645 10.7782 10.0645 10.0645C10.0645 9.35081 9.4879 8.77419 8.77419 8.77419C8.06048 8.77419 7.48387 9.35081 7.48387 10.0645C7.48387 10.7782 8.06048 11.3548 8.77419 11.3548ZM15.2258 8.93548C14.1895 8.93548 12.9718 9.61694 12.8105 10.6331C12.7419 11.0847 13.2742 11.3669 13.6089 11.0685L13.9919 10.7258C14.5887 10.1935 15.8548 10.1935 16.4516 10.7258L16.8347 11.0685C17.1774 11.3669 17.7056 11.0806 17.6331 10.6331C17.4798 9.61694 16.2621 8.93548 15.2258 8.93548Z" fill="black" fill-opacity="0.35"/>
                </svg>

            </div>
            <div slot="emoji-picker" class="emoji-container" slot-scope="{ emojis, insert, display }">
                <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                <div class="emoji-picker__search">
                    <input type="text" v-model="search" v-focus>
                </div>
                <div>
                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                        <h5>{{ category }}</h5>
                        <div class="emojis">
                            <span
                            v-for="(emoji, emojiName) in emojiGroup"
                            :key="emojiName"
                            @click="insert(emoji)"
                            :title="emojiName"
                            >{{ emoji }}</span>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </emoji-picker>
    </div>
</template>

<script>
import EmojiPicker from 'vue-emoji-picker';

export default {
    components: {
        EmojiPicker,
    },

    props: {
        modelValue: {
            type: String,
            default: ''
        },
        input: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            value: this.modelValue,
            search: '',
        }
    },

    model: {
        prop: 'modelValue',
        event: 'input'
    },

    methods: {
        append(emoji) {
            this.value += emoji;

            this.$emit('input', this.value)
        },
        update() {
            this.$emit('input', this.value)
        },
    },

    directives: {
        focus: {
            inserted(el) {
                el.focus()
            },
        },
    },

    watch: {
        modelValue(value) {
            if (!value) {
                this.value = '';
                this.search = '';
            }

            if (this.modelValue != this.value) {
                this.search = '';
                this.value = this.modelValue;
            }
        }
    }

}
</script>