<template>
    <div class="sh-d px-10 pt-6 pb-24 bg-gray-200 content content-page-mode">
        <page-header
            :branding="false"
            class="absolute z-20 w-full top-6"
            title="Shipment {consignment_no}: {status}"
        >
        </page-header>

        <div class="sh-d__body">
            <div class="sh-d__section sh-d__section--s">
                <div class="sh-d__card sh-d__h-100">
                    <div class="sh-d__head flex flex__align-center flex__justify-between">
                        <div class="flex flex__align-center">
                            <button class="icon-btn icon-btn-blue cursor-default">
                                <fa :icon="['fal','truck']" />
                            </button>

                            <span
                                class="f-size-14 ml-2 break-words color-pink"
                                style="max-width: 180px"
                            >Exception: {lastXception.exception_details}</span>
                        </div>

                        <div
                            class="f-size-14 no-wrap color-pink"
                        >{lastXception.created_at)</div>
                    </div>

                    <div class="sh-d__progress">
                         <div class="sh-d-progress">
                            <div class="sh-d-progress__item">
                                <div class="flex flex__grow">
                                    <div class="sh-d-circle">
                                        <div class="sh-d-circle__top"></div>
                                        <div class="sh-d-circle__circle"><span></span></div>
                                        <div class="sh-d-circle__line sh-d-circle__line--min"></div>
                                        <div class="sh-d-circle__line"></div>
                                    </div>

                                    <div class="w__100-p">
                                        <div class="sh-d-progress__content">
                                            <div class="sh-d-progress__head z-10 relative">
                                                <div>
                                                    <div class="sh-d-progress__action f-w-bold f-size-18 flex flex__align-center">
                                                        { events[0].group }
                                                    </div>
                                                </div>

                                                <div class="ml-5 flex flex__align-center">
                                                    <div class="f-w-bold f-size-sm text-right no-wrap">{events[0].originated_at}</div>
                                                    <!--                            <div class="f-s-10 color-grey text-right letter-1-2 mt-1 sh-d-progress__head-text-min">{{ prepareTime(value.events[0].time) }} UTC</div>-->
                                                </div>
                                            </div>
                                        </div>

                                        <div class="sh-d-progress__body">
                                            <div class="sh-d-progress__subitem-wr active">

                                                <div class="pt-2 pb-2 z-10">
                                                    <div class="f-w-bold f-size-sm text-right no-wrap">{ event.originated_at }</div>
                                                </div>

                                                <div class="flex flex__justify-between w-full">
                                                    <div class="cursor-pointer">
                                                        <div class="f-size-14 leading-tight">
                                                            { event.name }
                                                            <fa class="sh-d-progress__chevron ml-2 rotate-180" :icon="['fal','chevron-down']" />
                                                        </div>
                                                        <div class="f-s-10 color-grey mt-1">{event.location}</div>
                                                    </div>

                                                    <div>
                                                        <div class="f-s-10 color-grey letter-1-2 no-wrap ml-3">{event.originated_at} UTC</div>
                                                    </div>
                                                </div>

                                                <div class="w__100-p pt-3">
                                                    <div class="sh-d-progress__detail z-10 relative">
                                                        <div class="sh-d-progress__subitem pl-0">
                                                            <div class="sh-d-progress__desc">
                                                                <div class="f-s-10 color-grey uppercase">
                                                                    xception details
                                                                    <fa :icon="['fal','pen']" class="ml-2 cursor-pointer" />
                                                                </div>
                                                                <div class="f-size-14 leading-tight mt-1">{ xception.exception_details}</div>
                                                            </div>

                                                            <div class="flex">
                                                                <button class="px-10 btn-primary pl-2 pr-2">Resolve</button>
                                                                <button class="px-10 btn-primary yellow pl-2 pr-2 ml-2">Investigate</button>
                                                            </div>
                                                        </div>

                                                        <div class="sh-d-progress__subitem">
                                                            <div class="sh-d-progress__desc">
                                                                <div class="f-s-10 color-grey uppercase">Carrier status</div>
                                                                <div class="f-size-14 leading-tight mt-1">{xception.status}</div>
                                                            </div>
                                                        </div>

                                                        <div class="sh-d-progress__subitem">
                                                            <div class="sh-d-progress__half sh-d-progress__desc">
                                                                <div class="f-s-10 color-grey uppercase">created</div>
                                                                <div class="f-size-14 leading-tight mt-1">{xception.created_at}</div>
                                                            </div>

                                                            <div class="sh-d-progress__half sh-d-progress__desc">
                                                                <div class="f-s-10 color-grey uppercase">Claimed by</div>
                                                                <div class="f-size-14 leading-tight color-grey mt-1">Not claimed</div>
                                                                <div class="f-size-14 leading-tight mt-1">{xception.claimedBy.name}</div>
                                                            </div>
                                                        </div>

                                                        <div class="sh-d-progress__subitem">
                                                            <div class="sh-d-progress__half sh-d-progress__desc">
                                                                <div class="f-s-10 color-grey uppercase">Resolved</div>
                                                                <div class="f-size-14 leading-tight mt-1">{xception.resolved_at}</div>
                                                            </div>

                                                            <div class="sh-d-progress__half sh-d-progress__desc">
                                                                <div class="f-s-10 color-grey uppercase">Resolved by</div>
                                                                <div class="f-size-14 leading-tight mt-1">{xception.resolvedBy.name}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <div class="sh-d-progress__item">
                                 <div class="flex flex__grow">
                                     <div class="sh-d-circle">
                                         <div class="sh-d-circle__top"></div>
                                         <div class="sh-d-circle__circle sh-d-circle--empty"><span></span></div>
                                         <div class="sh-d-circle__line sh-d-circle--empty sh-d-circle__line--min"></div>
                                         <div class="sh-d-circle__line sh-d-circle--empty"></div>
                                     </div>

                                     <div class="w__100-p">
                                         <div class="sh-d-progress__content">
                                             <div class="sh-d-progress__head z-10 relative">
                                                 <div>
                                                     <div class="sh-d-progress__action f-w-bold f-size-18 flex flex__align-center">
                                                        static DELIVERED
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                    </div>

                    <div class="sh-d-comment" :class="{ 'sh-d-comment_no-active': true }">
                        <div class="flex w__100-p pt-4 pb-4 pl-6 pr-6 flex__justify-between flex__align-center sh-d-comment__head">
                            <div class="sh-d-progress__action f-w-bold f-size-16 flex flex__align-center cursor-pointer">
                                Shipment comments 10
                                <fa class="sh-d-progress__chevron ml-2" :class="{'rotate-180': true}" :icon="['fal','chevron-down']" />
                            </div>

                             <div class="flex">
                                <div class="f-size-14 color-green cursor-pointer">Add comment +</div>
                            </div>
                        </div>

                        <div class="sh-d-comment__content pl-6 pr-6">
                            <div  class="sh-d-progress__comments-body pb-1">
                                <div class="sh-d-message mb-4">
                                    <div class="flex flex__justify-between flex__align-center mb-2">
                                        <div class="f-s-10 color-grey">{comments.user.name}</div>
                                        <div class="f-s-10 color-grey letter-1-2 flex">
                                            {comments.created_at}

                                            <div class="w-4 cursor-pointer ml-2 flex flex__justify-end" >
                                                <fa :icon="['fal','pen']" class="f-size-12" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="f-size-12 leading-tight color-grey-65 break-words">
                                        {comment.text}
                                        <span class="ml-1 color-grey">(edited { comment.changes[comment.changes.length - 1].created_at}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form>
                            <div class="pl-6 pr-6 pt-3">
                                <emoji-text-fields input />
                            </div>

                            <div class="pl-6 pr-6 sh-d__comments-actions pb-4 z-10 relative">
                                <div class="flex flex__justify-between flex__align-center  mt-3">
                                    <div class="flex">
                                        <div class="flex flex__align-center mr-3">
                                            <input type="radio" :value="1" class="form-radio no-branding mr-3"/>
                                            <label for="1">Int</label><br>
                                        </div>
                                        <div class="flex flex__align-center mr-3">
                                            <input type="radio" :value="0" class="form-radio no-branding mr-3"/>
                                            <label for="1">Ext</label><br>
                                        </div>
                                    </div>

                                    <div class="comments-buttons flex">
                                        <button class="px-10 btn-primary pl-6 pr-6 background-blue mr-3 border-0" type="submit">
                                            Send
                                        </button>
                                        <button class="icon-btn background-pink">
                                            <fa :icon="['fal', 'times']" class="path-white" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="sh-d__section sh-d__section--m flex flex__column">
                <div class="sh-d__card">
                    <div class="flex flex__justify-between">
                        <div class="sh-d__section-item">
                            <div class="sh-d__head flex flex__align-center flex__justify-between">
                                <div class="flex flex__align-center">
                                    <button class="icon-btn icon-btn-blue cursor-default">
                                        <fa :icon="['fal','plane-departure']" />
                                    </button>

                                    <span class="f-size-16 ml-2">Pickup</span>
                                </div>
                            </div>
                        </div>

                        <div class="sh-d__section-item">
                            <div class="sh-d__head flex flex__align-center flex__justify-between pl-0">
                                <div class="flex flex__align-center">
                                    <button class="icon-btn icon-btn-blue icon-btn-custom cursor-default">
                                        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 6.5V5.04122C17.0042 5.04347 17.0085 5.04579 17.0129 5.04818C17.1281 5.11049 17.2863 5.20401 17.4762 5.32256C17.8544 5.55871 18.3374 5.88112 18.8131 6.21981C19.2903 6.55954 19.7505 6.90879 20.0872 7.19905C20.2232 7.31625 20.3317 7.41751 20.4107 7.5C20.3317 7.5825 20.2232 7.68375 20.0872 7.80095C19.7505 8.09121 19.2903 8.44046 18.8131 8.78019C18.3374 9.11888 17.8544 9.44129 17.4762 9.67744C17.2863 9.79599 17.1281 9.88951 17.0129 9.95182C17.0085 9.95421 17.0042 9.95653 17 9.95879V8.5V8H16.5H13.5V7H16.5H17V6.5ZM20.5289 7.64036C20.5331 7.64736 20.5318 7.64661 20.5281 7.63902C20.5284 7.6395 20.5286 7.63995 20.5289 7.64036ZM20.5281 7.36098C20.5318 7.35339 20.5331 7.35264 20.5289 7.35964C20.5286 7.36005 20.5284 7.3605 20.5281 7.36098ZM10.5 4C10.5 5.93323 8.93323 7.5 7 7.5C5.06677 7.5 3.5 5.93323 3.5 4C3.5 2.06677 5.06677 0.5 7 0.5C8.93323 0.5 10.5 2.06677 10.5 4ZM7 10C7.84791 10 8.65504 9.8192 9.38502 9.5H9.8C11.8426 9.5 13.5 11.1574 13.5 13.2V14.5C13.5 15.052 13.052 15.5 12.5 15.5H1.5C0.948017 15.5 0.5 15.052 0.5 14.5V13.2C0.5 11.1574 2.15739 9.5 4.2 9.5H4.61536C5.34718 9.81893 6.15143 10 7 10Z" stroke="#00A3FF"/>
                                        </svg>
                                    </button>

                                    <span class="f-size-16 ml-2">Sender</span>
                                </div>
                            </div>
                        </div>

                        <div class="sh-d__section-item">
                            <div class="sh-d__head flex flex__align-center flex__justify-between pl-0">
                                <div class="flex flex__align-center">
                                    <button class="icon-btn icon-btn-blue cursor-default">
                                        <fa :icon="['fal','clock']" />
                                    </button>

                                    <span class="f-size-16 ml-2">Timeline</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pt-4">
                        <div class="flex flex__justify-between">
                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">company</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {pickup_name}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">company</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {consignor_name}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Requsted pickup date</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">{transportdates_pickupdate_initial}</div>
                            </div>
                        </div>

                        <div class="flex flex__justify-between">

                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Country</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {pickup_countrycode}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Country</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {consignor_countrycode}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Confirmed pickup date</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">{transportdates_etddate_initial}</div>
                            </div>
                        </div>

                        <div class="flex flex__justify-between">

                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">City</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {pickup_city}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">City</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {consignor_city}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Current ETD</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">{transportdates_etddate}</div>
                            </div>
                        </div>

                        <div class="flex flex__justify-between">

                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Address</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {pickup_address}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Address</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {consignor_address}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Actual pickup date</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">{transportdates_pickupdate}</div>
                            </div>
                        </div>

                        <div class="flex flex__justify-between">

                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Postcode</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {pickup_postcode}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Postcode</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {consignor_postcode}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4"></div>
                        </div>

                        <div class="flex flex__justify-between">
                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Contact</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    <div>{pickup_contactperson}</div>

                                    <div class="break-words">
                                        {pickup_email}
                                    </div>

                                    <div>{pickup_phone}</div>
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Contact</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    <div>{consignor_contactperson}</div>

                                    <div class="break-words">
                                        {consignor_email}
                                    </div>
                                    
                                    <div>{consignor_phone}</div>
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                            </div>
                        </div>
                    </div>

                    <div class="pl-6 pt-4 pr-6 pb-12 border__top-grey-1">
                        <div class="sh-d-section__item">
                            <div class="f-s-10 color-grey uppercase">Pickup instruction</div>
                            <div class="f-size-13 leading-tight mt-1 lh-28">{pickupinstructions}</div>
                        </div>
                    </div>
                </div>

                <div class="sh-d__card mt-2 flex__grow">
                    <div class="flex flex__justify-between">
                        <div class="sh-d__section-item">
                            <div class="sh-d__head flex flex__align-center flex__justify-between">
                                <div class="flex flex__align-center">
                                    <button class="icon-btn icon-btn-green cursor-default">
                                        <fa :icon="['fal','plane-arrival']" />
                                    </button>

                                    <span class="f-size-16 ml-2">Delivery</span>
                                </div>
                            </div>
                        </div>

                        <div class="sh-d__section-item">
                            <div class="sh-d__head flex flex__align-center flex__justify-between pl-0">
                                <div class="flex flex__align-center">
                                    <button class="icon-btn icon-btn-green icon-btn-custom cursor-default">
                                        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 8.5V9.95879C4.00421 9.95653 4.00852 9.95421 4.01293 9.95182C4.12812 9.88951 4.28634 9.79599 4.47621 9.67744C4.85444 9.44129 5.33742 9.11888 5.81313 8.78019C6.29029 8.44046 6.75046 8.09121 7.08724 7.80095C7.22323 7.68375 7.33165 7.5825 7.4107 7.5C7.33165 7.41751 7.22323 7.31625 7.08724 7.19905C6.75046 6.90879 6.29029 6.55954 5.81313 6.21981C5.33742 5.88112 4.85444 5.55871 4.47621 5.32256C4.28634 5.20401 4.12812 5.11049 4.01293 5.04818C4.00852 5.04579 4.00421 5.04347 4 5.04122V6.5V7H3.5H0.5V8H3.5H4V8.5ZM7.52887 7.35964C7.53306 7.35264 7.53178 7.35339 7.52806 7.36098C7.52836 7.3605 7.52863 7.36005 7.52887 7.35964ZM7.52806 7.63902C7.53178 7.64661 7.53306 7.64736 7.52887 7.64036C7.52863 7.63995 7.52836 7.6395 7.52806 7.63902ZM10.5 4C10.5 5.93323 12.0668 7.5 14 7.5C15.9332 7.5 17.5 5.93323 17.5 4C17.5 2.06677 15.9332 0.5 14 0.5C12.0668 0.5 10.5 2.06677 10.5 4ZM14 10C13.1521 10 12.345 9.8192 11.615 9.5H11.2C9.15739 9.5 7.5 11.1574 7.5 13.2V14.5C7.5 15.052 7.94802 15.5 8.5 15.5H19.5C20.052 15.5 20.5 15.052 20.5 14.5V13.2C20.5 11.1574 18.8426 9.5 16.8 9.5H16.3846C15.6528 9.81893 14.8486 10 14 10Z" stroke="#20984B"/>
                                        </svg>
                                    </button>

                                    <span class="f-size-16 ml-2">Receiver</span>
                                </div>
                            </div>
                        </div>

                        <div class="sh-d__section-item">
                            <div class="sh-d__head flex flex__align-center flex__justify-between pl-0">
                                <div class="flex flex__align-center">
                                    <button class="icon-btn icon-btn-green cursor-default">
                                        <fa :icon="['fal','clock']" />
                                    </button>

                                    <span class="f-size-16 ml-2">Timeline</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pt-4">
                        <div class="flex flex__justify-between">
                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">company</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {delivery_name}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">company</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {consignee_name}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Requsted delivery date</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">{transportdates_deliverydate_initial}</div>
                            </div>
                        </div>

                        <div class="flex flex__justify-between">

                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Country</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {delivery_countrycode}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Country</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {consignee_countrycode}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Confirmed delivery date</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">{transportdates_etadate_initial}</div>
                            </div>
                        </div>

                        <div class="flex flex__justify-between">

                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">City</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {delivery_city}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">City</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {consignee_city}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Estimated Delivery Date</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">{transportdates_etadate}</div>
                            </div>
                        </div>

                        <div class="flex flex__justify-between">

                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Address</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {delivery_address}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Address</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {consignee_address}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Actual delivery date</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">{transportdates_deliverydate}</div>
                            </div>
                        </div>

                        <div class="flex flex__justify-between">

                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Postcode</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {delivery_postcode}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Postcode</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    {consignee_postcode}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4"></div>
                        </div>

                        <div class="flex flex__justify-between">
                            <div class="pl-6 sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Contact</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    <div>{delivery_contactperson}</div>

                                    <div class="break-words">
                                        {delivery_email}
                                    </div>

                                    <div>{delivery_phone }</div>
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                                <div class="f-s-10 color-grey uppercase">Contact</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    <div>{consignee_contactperson}</div>

                                    <div class="break-words">
                                        {consignee_email}
                                    </div>

                                    <div>{consignee_phone}</div>
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-6 mb-4">
                            </div>
                        </div>
                    </div>

                    <div class="pl-6 pt-4 pr-6 pb-12 border__top-grey-1">
                        <div class="sh-d-section__item">
                            <div class="f-s-10 color-grey uppercase">Delivery instruction</div>
                            <div class="f-size-13 leading-tight mt-1 lh-28">{deliveryinstructions}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sh-d__section sh-d__section--e" style="min-width: 300px">
                <div class="sh-d__card sh-d__h-100">
                    <div class="sh-d-additional">
                        <div class="sh-d-additional__item pt-3 pl-6 pr-6 pb-2">
                            <div class="flex flex__align-center sh-d-additional__head mb-3">
                                <span class="f-size-16 mr-2">Owner</span>

                            </div>

                            <div class="sh-d-additional__list">
                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Organization</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{owner}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Site</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{owner_site}</div>
                                </div>
                            </div>
                        </div>

                        <div class="sh-d-additional__item pt-3 pl-6 pr-6 pb-2">
                            <div class="flex flex__align-center sh-d-additional__head mb-3">
                                <span class="f-size-16 mr-2">Carrier</span>
                            </div>

                            <div class="sh-d-additional__list">
                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Mode</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{transport_mode}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Carrier</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{carrier_data.carrier_name}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Service</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{carrier_data.carrier_service_name}</div>
                                </div>
                            </div>
                        </div>

                        <div class="sh-d-additional__item pt-3 pl-6 pr-6 pb-2">
                            <div class="flex flex__align-center sh-d-additional__head mb-3">
                                <span class="f-size-16 mr-2">Goods info</span>
                            </div>

                            <div class="sh-d-additional__list">
                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Packages</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{noofpackages}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Volume</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{dimensions_volume} {dimensions_volume_unit}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Weight</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{dimensions_weight} {dimensions_weight_unit}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Loading meters</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{dimensions_loadingmetres}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Type</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{goods_type_name}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Description</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{goods_description}</div>
                                </div>
                            </div>
                        </div>

                        <div class="sh-d-additional__item pt-3 pl-6 pr-6 pb-2">
                            <div class="flex flex__align-center sh-d-additional__head mb-3">
                                <span class="f-size-16 mr-2">Price & Terms</span>
                            </div>

                            <div class="sh-d-additional__list">
                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Price</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{price_data.amount} <span>{price_data.currency}</span></div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Who pays</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{payer}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Incoterms</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{combiterms}</div>
                                </div>
                            </div>
                        </div>

                        <div class="sh-d-additional__item pt-3 pl-6 pr-6 pb-2">
                            <div class="flex flex__align-center sh-d-additional__head mb-3">
                                <span class="f-size-16 mr-2">Reference</span>
                            </div>

                            <div class="sh-d-additional__list">
                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Senders ref.</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{order_no}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Recipients ref.</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{consignee_reference}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Template</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{template}</div>
                                </div>
                            </div>
                        </div>

                        <div class="sh-d-additional__item pt-3 pl-6 pr-6 pb-2">
                            <div class="flex flex__align-center sh-d-additional__head mb-3">
                                <span class="f-size-16 mr-2">Footprint</span>
                            </div>

                            <div class="sh-d-additional__list">
                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Actual footprint</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">{actual_footprint}</div>
                                </div>

                                <div class="flex flex__justify-between sh-d-additional__list-item mb-2">
                                    <div class="f-s-10 color-grey uppercase w__100-p w__min-50-p mt-1 mr-2">Trees per year needed to absorb</div>

                                    <div class="f-size-12 w__100-p w__min-50-p pl-2 break-words">
                                        { actual_footprint }/21772.4338
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmojiTextFields from '~/components/EmojiTextFields.vue';

export default {
    components: {
        EmojiTextFields,
    },

    name: "detail",

    layout: 'static',

    metaInfo() {
        return { title: "Detail" };
    },

    data() {return {}},
};
</script>

<style lang="scss">
.sh-d-circle--empty {
    span {
        background-color: #cfcfcf;
    }
}
.sh-d-circle--empty {
    border-color: #cfcfcf;
}

.comments-buttons {
    button[disabled] {
        pointer-events: none;
    }
}

.sh-d__emissions > .modal {
    overflow: auto !important;
}
</style>
